<template>
  <div class="order_manage">
    <div class="order_manage_nav">
      <div
        @click="changeNav(item.path)"
        v-for="(item, index) in list"
        :key="index"
        :class="[item.path == currentPath ? 'active' : '']"
      >
        <div>
          <span class="order_num_circular">{{
            orderNums[item.count_field]
          }}</span>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="order_manage_content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPath: '/orderManage/experienceOrder',
      list: [
        {
          name: '客户线索',
          path: '/orderManage/experienceOrder',
          count_field: 'experience_order_count',
        },
        {
          name: '线上下单',
          path: '/orderManage/onlineOrder',
          count_field: 'online_order_count',
        },
      ],
    }
  },
  computed: {
    orderNums() {
      return this.$store.state.order_nums
    },
  },
  watch: {
    $route(to, from) {
      this.currentPath = to.path
    },
  },
  methods: {
    changeNav(path) {
      if (path != this.$route.path) {
        this.$router.push(path)
      }
    },
  },
  mounted() {
    this.currentPath = this.$route.path
    this.$store.dispatch('getOrderNum')
  },
}
</script>

<style lang="less" scoped>
.order_manage {
  padding: 0 32px;
  display: flex;
  height: 100%;
  .order_manage_nav {
    padding: 4px 0;
    width: 10%;
    height: 100%;
    background-color: #fff;
    margin-right: 2px;
    > div {
      padding: 20px 0 20px 30px;
      cursor: pointer;
      &.active {
        background-color: rgba(230, 247, 255);
        border-right: 4px solid #1890ff;
        color: #1890ff;
      }
      > div {
        display: inline-block;
        position: relative;
        .order_num_circular {
          position: absolute;
          display: inline-block;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          right: -20px;
          top: -14px;
          background-color: #f43530;
        }
      }
    }
  }
  .order_manage_content {
    flex: 1;
    background-color: #fff;
  }
}
</style>
